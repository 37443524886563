@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("./fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Calibri-Regular";
    src: url("./fonts/Calibri-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Swiss721BT-BoldCondensed";
    src: url("./fonts/Swiss 721 Bold Condensed BT.ttf") format("truetype");
}

@font-face {
    font-family: "Segoe UI";
    src: url("./fonts/Segoe UI.ttf") format("truetype");
}

body {
    margin: 0;
    padding: 0;
    font-family: "Calibri-Regular", serif;
}

.x-ray__table__header {
    text-align: left;
}

@media screen and (max-width: 768px) {
    thead .x-ray__table__header {
        text-align: center;
    }
}

.x-ray__table__value {
    text-align: left;
}

@media screen and (max-width: 768px) {
    tr .x-ray__table__value {
        text-align: center !important;
    }
}

.img-desktop {
    display: block;
}